<template>
  <div class="bg-main">
    <Header></Header>
    <div class="content">
      <div class="pagemain">
        <div class="lh20 fs14 mb20">
          <span class="col9 pointer plans">当前位置: 首页 》</span>
<!--          <span class="col9 pointer plans">热销商品 》</span>-->
          <span class="col9 pointer plans">商品详情 》</span
          ><span class="col3 pointer">确认订单</span>
        </div>
        <div class="main">
          <template v-if="state == 0">
            <div class="bg-white br6 pt50 pr97 pl82 pb45 border_b">
              <div class="fs24 col3 lh34 fwb mb10">邮箱地址</div>
              <div class="fs14 col9 lh20 mb20">
                支付完成后我们将把您购买的商品发送到您输入的邮箱地址
              </div>
              <input
                class="input fs16 col9"
                placeholder="请输入邮箱地址"
                v-model="email"
                type="text"
              />
              <div class="fs24 col3 lh34 fwb mb30">确认商品</div>
              <div class="border_b flex-box pb47" v-for="(item,index) in goods">
                <img class="imgs1" :src="item.image" alt="" />
                <div class="flex-grow-1 flex-box flex-between fs16 col3 fwb">
                  <div>{{item.goods_name}}</div>
                  <div>{{item.key_name}}</div>
                  <div>数量:{{item.num}}</div>
                  <div>单价：{{selected_money.unit}}{{item.goods_price}}</div>
                </div>
              </div>
              <div class="flex-box flex-end mt20">
                <div class="fs14 col6 lh20 mr65">订单总金额</div>
                <div class="fs14 col3 lh20">{{selected_money.unit}}{{total_fee}}</div>
              </div>
              <div class="flex-box flex-end mt15">
                <div class="fs14 col6 lh20 mr50">支付金额</div>
                <div class="fs20 red fwb lh28">{{selected_money.unit}}{{total_fee}}</div>
              </div>
              <div class="mt55 fs24 col3 fwb lh34 mb40">选择支付方式</div>
              <div class="pay_part pointer">
                <img class="icon" src="../assets/images/pay_icon.png" alt="" />
              </div>
            </div>
            <div class="flex-box flex-end pt20 pr72 pb55">
              <div class="fs14 col6 lh20 mr12">需支付</div>
              <div class="fs20 red lh28 fwb mr50">{{selected_money.unit}}{{total_fee}}</div>
              <div class="ffs18 colf tc btn_buy pointer" @click="saveOrder">立即支付</div>
            </div>
          </template>
          <template v-if="state == 1">
            <div class="pt50 pl50 fs23 col3 fwb mb30 lh34">订单信息</div>
            <div class="pl50 fs14 col3 lh20 mb50">
              <span class="mr146">订单号: {{order.order_no}}</span
              ><span>支付金额: {{order.order_no}}</span>
            </div>
            <div class="fs24 col3 fwb lh34 mb40 pl50">支付订单</div>
            <img class="code_img" src="../assets/images/imgs1.png" alt="" />
            <div class="tc fs16 col3 lh22 mb55">
              打开手机微信/支付宝/银联扫码支付
            </div>
            <div class="payed_btn colf tc fs18 pointer">我已支付</div>
          </template>
          <template v-if="state == 2">
            <div class="pt80">
              <img
                class="success_icon"
                src="../assets/images/success_icon.png"
                alt=""
              />
              <div class="tc fs16 col0 lh22 mb20 op6">支付成功</div>
              <div class="btn_order fs14 col0 tc pointer">查看订单</div>
            </div>
          </template>
          <template v-if="state == 3">
            <div class="pt80">
              <img
                class="success_icon"
                src="../assets/images/fail_icon.png"
                alt=""
              />
              <div class="tc fs16 col0 lh22 mb20 op6">支付失败</div>
              <div class="btn_order fs14 col0 tc pointer">重新支付</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
      <script>
      import CryptoJS from 'crypto-js';
      import {apiGetOrderShow, apiGetUserInfo, apiSaveOrder} from "../request/api";
export default {
  data() {
    return {
      state: 0, //0确认商品 1支付订单 2支付成功 3支付失败
      email:'',
      order:'',
      goods_id:0,
      key:'',
      selected_money_id:0,
      num:0,
      ad_id:0,
      goods:[],
      total_fee:'',
      selected_money:null,
    };
  },
  created() {
    let _that = this;
    let params=_that.$route.query;
    if(params.params){
      let rec=params.params;
      var c=this.decryptData(decodeURIComponent(rec));
      console.log(c)
      if(c.goods_id){
        _that.goods_id=c.goods_id;
      }
    if(c.key){
      _that.key=c.key;
    }
      if(c.ad_id){
        _that.ad_id=c.ad_id;
      }
      if(c.selected_money_id){
        _that.selected_money_id=c.selected_money_id;
      }
      if(c.num){
        _that.num=c.num;
      }

      /**
       * goods_id: '2', key: '3_2', num: 0, ad_id: 0,selected_money_id
       */
    }
    this.getOrderShow()
  },
  watch: {
    $route(to, from) {
      let _that = this;
      let params = _that.$route.query;
      if (params.params) {
        let rec = params.params;
        var c = this.decryptData(decodeURIComponent(rec));
        console.log(c)
        if (c.goods_id) {
          _that.goods_id = c.goods_id;
        }
        if (c.key) {
          _that.key = c.key;
        }
        if (c.ad_id) {
          _that.ad_id = c.ad_id;
        }
        if (c.selected_money_id) {
          _that.selected_money_id = c.selected_money_id;
        }
        if (c.num) {
          _that.num = c.num;
        }
      }
    },
  },
  mounted() {},

  methods: {
    getOrderShow()
    {
      var json={},that=this;
      json.goods_id=that.goods_id;
      json.ad_id=that.ad_id;
      json.key=that.key;
      json.selected_money_id=that.selected_money_id;
      json.num=that.num;
      apiGetOrderShow(json).then(res => {
        // 获取数据成功后的其他操作
        console.log(res)
        if(res.code==1){
         that.goods=res.data.goods;
         that.total_fee=res.data.total_fee;
         that.selected_money=res.data.selected_money;
        }else{
          this.$router.push({ path: "/" });
        }

      }).catch(err=>{

      })
    },
    encryptData(data) {
      const secretKey = 'yxcz'; // 替换为你的密钥
      return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    },

// 解密方法
    decryptData(encryptedData) {
      const secretKey = 'yxcz'; // 替换为你的密钥
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return originalData;
    },
    saveOrder()
    {
      var json={},that =this;
      var that = this;
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (!that.email) {
        return that.$message.error("请输入邮箱");
      }
      if (!regEmail.test(that.email)) {
        // 合法的邮箱
        return that.$message.error("邮箱格式错误");
      }
      json.email=that.email;
      json.ad_id=that.ad_id;
      json.goods=JSON.stringify(that.goods);
      json.selected_money_id=that.selected_money_id;
      json.total_fee=that.total_fee;
      apiSaveOrder(json).then(res => {
        // 获取数据成功后的其他操作
        console.log(res)
        if(res.code==1){
          that.state=3;
          that.order=res.data;
        }else{
          return that.$message.error(res.msg);
          // this.$router.push({ path: "/" });
        }

      }).catch(err=>{

      })
    },
  },
};
</script>
      <style lang="less" scoped>
@import "../assets/css/style.css";
.content {
  padding-top: 17px;
  max-width: 100vw;
  margin-bottom: 0;
  background: #f8f8f8;
}

.pagemain {
  width: 1210px;
  margin: 0 auto 50px;
  .main {
    min-height: 920px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    .input {
      width: 477px;
      padding-left: 30px;
      height: 62px;
      line-height: 62px;
      background: rgba(186, 202, 209, 0.2);
      border-radius: 3px;
      margin-bottom: 53px;
    }
    .imgs1 {
      width: 85px;
      height: 85px;
      display: block;
      object-fit: cover;
      border-radius: 6px;
      overflow: hidden;
      margin-right: 22px;
    }
  }
  .btn_buy {
    width: 158px;
    height: 55px;
    line-height: 55px;
    background: #a074fb;
    border-radius: 28px;
  }
  .pay_part {
    width: 238px;
    height: 75px;
    padding: 16px 0;
    border-radius: 10px;
    border: 1px solid #979797;
    .icon {
      width: 137px;
      height: 41px;
      display: block;
      margin: 0 auto;
    }
  }
}
.code_img {
  width: 212px;
  height: 212px;
  display: block;
  object-fit: cover;
  margin: 0 auto 10px;
}
.payed_btn {
  width: 220px;
  height: 55px;
  line-height: 55px;
  background: #a074fb;
  border-radius: 28px;
  margin: 0 auto;
}
.success_icon {
  width: 91px;
  height: 91px;
  display: block;
  object-fit: cover;
  margin: 0 auto 14px;
}
.btn_order {
  width: 176px;
  text-align: center;
  height: 48px;
  line-height: 46px;
  border-radius: 39px;
  border: 1px solid rgba(151, 151, 151, 0.5);
  margin: 0 auto;
}
</style>
      
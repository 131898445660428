<template>
  <div class="main">
    <div class="flex-box pt50">
      <div class="flex-1">

        <img class="logo" :src="logo" alt="">
        <img class="login_icon" src="../assets/images/login_icon.png" alt="" />
      </div>
      <div>
        <div class="loginPop">
          <div v-if="login == 0" class="fs24 col3 tc lh34 mb30 login_title ">
            欢迎登陆
          </div>
          <div v-if="login == 1" class="fs24 col3 tc lh34 mb30 login_title ">
            注册账号
          </div>
          <div v-if="login == 2" class="fs24 col3 tc lh34 mb30 login_title ">
            忘记密码
          </div>
          <div v-if="login == 3" class="fs24 col3 tc lh34 mb30 login_title ">
            重新设置密码
          </div>
          <div v-if="login != 3" class="input_box fs14 col3 flex-box mb20">
            <input
              type="text"
              class="flex-grow-1"
              placeholder="请输入邮箱"
              v-model="email"
            />
          </div>
          <div
            v-if="login == 1 || (login == 2 && login != 3)"
            class="input_box fs14 col3 flex-box mb20"
          >
            <input
              type="text"
              class="flex-grow-1"
              placeholder="请输入邮箱验证码"
              v-model="code2"
            />
            <a
              href="javascript:;"
              class="send sendcode col-purple pointer plans"
              v-if="show"
              @click="sendSms"
              >获取验证码</a
            >

            <a href="javascript:;" v-else class="send count col-purple"
              >{{ count }}s</a
            >
          </div>

          <div
            v-if="login == 0 || login == 1 || login == 3"
            class="input_box fs14 col3 flex-box mb20"
          >
            <input
              type="password"
              class="flex-grow-1"
              placeholder="请输入密码"
              v-model="password"
            />
          </div>
          <div
            v-if="login == 1 || login == 3"
            class="input_box fs14 col3 flex-box mb20"
          >
            <input
              type="password"
              class="flex-grow-1"
              placeholder="请再次输入密码"
              v-model="new_password"
            />
          </div>
          <div v-if="login == 0" class="input_box fs14 col3 flex-box mb20">
            <input
              type="text"
              class="flex-grow-1"
              placeholder="请输入验证码"
              v-model="code"
            />
            <div style="border: none" class="pointer" @click="refreshCode">
              <s-Identify :identifyCode="identifyCode"></s-Identify>
            </div>
          </div>
          <a v-if="login == 0" href="javascript:;" class="login_btn pointer"
             @click="submit"
            >立即登录</a
          >
          <a v-if="login == 1" href="javascript:;" class="login_btn pointer"
             @click="submit"
            >立即注册</a
          >
          <a v-if="login == 2" href="javascript:;" class="login_btn pointer" @click="goforget2">下一步</a>
          <a v-if="login == 3" href="javascript:;" class="login_btn pointer" @click="submit1">提交</a>
          <div v-if="login == 0 || login == 1" class="flex-box fs16 col3 mb25">
            <div v-if="login == 0" class="flex-grow-1 pointer" @click="goregist">
              <a class="plans">还没有账号？ </a><a class="col-orange pointer plans" >立即注册</a>
            </div>
            <div v-if="login == 1" class="flex-grow-1 pointer">
              <a class="plans">已有账号？ </a><a class="col-orange pointer plans" @click="gologin">返回登录</a>
            </div>
            <a class="pointer plans plans" @click="goforget">忘记密码？</a>
          </div>
          <div v-if="login == 2 || login == 3" class="tc fs16 col3 pointer">
            <a class="plans">已有账号？ </a><a class="col-purple plans" @click="gologin">返回登录</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import SIdentify from "@/components/identify";
import {
  userLogoin,
  apiEmsSend,
  ForgetPassword,
  apiGetConfig,
  apiGetUserInfo,
  apiGetForcheck,
  apiForget
} from "../request/api";
import cookie from "vue-cookies";
import axios from "axios";
let is_history=false;
export default {

  components: {
    "s-Identify": SIdentify,
  },
  data() {
    const validateCode = (rule, value, callback) => {
      if (this.identifyCode !== value) {
        this.loginForm.code = "";
        this.refreshCode();
        callback(new Error("请输入正确的验证码"));
      } else {
        callback();
      }
    };
    return {
      login: 0, // 0登录 1注册 2找回密码
      identifyCodes: "BUGT",
      identifyCode: "", //找回密码图形验证码
      show: true,
      code2: "",
      count: "",
      password:'',
      login_icon:'',
      email:'',
      code:'',
      logo:'',
      is_history:false,
      new_password:''
    };
  },

  beforeRouteEnter(to, from, next) {


    next(vm => {
      // 判断前一个页面path是否是登录页

     if(!from.name&&from.name!=null){
       if(from.name!='login'){
         is_history=true;
       }
     }


    })
  },
  watch: {
    identifyCode(v) {
      this.isDebugLogin && (this.loginForm.code = v);
    },
    $route(to, from) {
      this.refreshCode();
      this.get_config();
      this.getUserInfo();
    }
  },
  methods: {
    goforget2(){
      var that=this;
      var that = this;
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (!that.email) {
        return that.$message.error("请输入邮箱");
      }
      if (!regEmail.test(that.email)) {
        // 合法的邮箱
        return that.$message.error("邮箱格式错误");
      }
      var json={};
      json.email=that.email;
      json.password=that.password;
      json.event='resetpwd';
      if(that.code2==''){
        return that.$message.error("请输入邮箱验证码");
      }
      apiGetForcheck({ email: that.email, event: 'resetpwd',captcha:that.code2 })
          .then((data_res) => {
            console.log(data_res);
            if (data_res.code == 1) {
              that.login=3;
              that.password='';
              that.new_password=''
            } else {
              that.$message.error(data_res.msg);
            }
          });

    },
    goforget(){
      this.login=2;
      this.email='';
      this.code=''
    },
    goregist(){
      this.login=1;
      this.code2='';
      this.password='';
      this.new_password=''
    },
    gologin(){
       this.login=0;
       this.code2='';
       this.count=0;
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode1(this.identifyCodes, 4);
    },
    makeCode1(o, l) {
      console.log(o)
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }

    },
    sendSms: function () {
      var that = this;
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (!that.email) {
        return that.$message.error("请输入邮箱");
      }
      if (!regEmail.test(that.email)) {
        // 合法的邮箱
        return that.$message.error("邮箱格式错误");
      }
      const TIME_COUNT = 60;


      var event = "register";
      if (this.login>1) {
        event = "resetpwd";
      }

     apiEmsSend({ email: that.email, event: event })
        .then((data_res) => {
          console.log(data_res);
          if (data_res.code == 1) {
            if (!that.timer) {
              that.count = TIME_COUNT;
              that.show = false;
              that.timer = setInterval(() => {
                if (that.count > 0 && that.count <= TIME_COUNT) {
                  that.count--;
                } else {
                  that.show = true;

                  clearInterval(that.timer);

                  that.timer = null;
                }
              }, 1000);
            }
          } else {
            that.$message.error(data_res.msg);
          }
        });
    },

    submit(){
      var that = this;
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (!that.email) {
        return that.$message.error("请输入邮箱");
      }
      if (!regEmail.test(that.email)) {
        // 合法的邮箱
        return that.$message.error("邮箱格式错误");
      }
      var json={};
      json.email=that.email;
      json.password=that.password;
      if(that.login==0){
        if(!json.password){
          return that.$message.error("请填写密码");
        }
        if(!that.code){
          return that.$message.error("请填写验证码");
        }
        if(that.code!=that.identifyCode){
          return that.$message.error("验证码错误");
        }
        console.log(that)
        json.event='login';
      }else{
        if(that.login!=1){
          return that.$message.error("不能提交");
        }
        if(that.password==''){
          return that.$message.error("请输入输入密码");
        }
        if(that.new_password==''){
          return that.$message.error("请再次输入密码");
        }
        if(that.password!=that.new_password){
          return that.$message.error("输入密码不一致");
        }
        json.event='register';
        if(that.code2==''){
          return that.$message.error("请输入邮箱验证码");
        }
        json.captcha=that.code2
      }


      userLogoin(json).then(res => {
        // 获取数据成功后的其他操作
       if(res.code==1){
         cookie.set("yxcz_token", res.data.userinfo.token);
         cookie.set("yxcz_userinfo", res.data.userinfo);
         axios.defaults.headers.post["yxcz_token"] =
             res.data.userinfo.token; // 登录标识
         axios.defaults.headers.get["yxcz_token"] =
             res.data.userinfo.token; // 登录标识
         if(is_history){
           this.$router.go(-1);
         }else{
           this.$router.push({ path: "/" });
         }
       }else{
         that.$message.error(res.msg);
       }


      }).catch(err=>{

      })
    },
    submit1(){
      var that = this;
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (!that.email) {
        return that.$message.error("请输入邮箱");
      }
      if (!regEmail.test(that.email)) {
        // 合法的邮箱
        return that.$message.error("邮箱格式错误");
      }
      var json={};
      json.email=that.email;
      json.password=that.password;
      json.event='resetpwd';
      if(that.code2==''){
        return that.$message.error("请输入邮箱验证码");
      }
      if(that.password==''){
        return that.$message.error("请输入输入密码");
      }
      if(that.new_password==''){
        return that.$message.error("请再次输入密码");
      }
      if(that.password!=that.new_password){
        return that.$message.error("输入密码不一致");
      }
      json.captcha=that.code2;
      apiForget(json).then(res => {
        // 获取数据成功后的其他操作
        if(res.code==1){
          cookie.set("yxcz_token", res.data.userinfo.token);
          cookie.set("yxcz_userinfo", res.data.userinfo);
          axios.defaults.headers.post["yxcz_token"] =
              res.data.userinfo.token; // 登录标识
          axios.defaults.headers.get["yxcz_token"] =
              res.data.userinfo.token; // 登录标识
          if(is_history){
            this.$router.go(-1);
          }else{
            this.$router.push({ path: "/" });
          }
        }else{
          that.$message.error(res.msg);
        }


      }).catch(err=>{

      })
    },
    get_config() {
      var that =this;
      // 调用api接口，并且提供了两个参数
      // 调用api接口，并且提供了两个参数
      apiGetConfig({

      }).then(res => {
        // 获取数据成功后的其他操作
        that.logo=res.data.logo_h;


      }).catch(err=>{

      })
    },
    getUserInfo(){
      var json={},that=this;
      apiGetUserInfo(json).then(res => {
        // 获取数据成功后的其他操作
        if(res.data.is_login){
          this.$router.go(-1);
        }
        that.userInfo=res.data.userinfo;

      }).catch(err=>{

      })
    },
  },
  mounted() {
    //this.makeCode1(this.identifyCodes, 4);
  },
  created() {

    this.refreshCode();
    this.get_config();
    this.getUserInfo()
  },
};
</script>
  <style lang="less" scoped>
html,
body {
  background: #4d4083 !important;
}
.main {
  width: 100%;
  height: 100vh;
  background: #4d4083;
  padding-left: 116px;
}
.logo {
  width: 219px;
  height: 83px;
  display: block;
 object-fit: fill;
}
// .title {
//   font-family: SFNSDisplay;
// font-size: 31px;
// color: #FFFFFF;
// line-height: 37px;
// text-align: center;
// font-style: normal;
// }
.login_icon {
  widows: 754px;
  height: 508px;
  display: block;
  object-fit: contain;
}
// 登录
.loginPop {
  margin-left: 80px;
  padding: 40px 35px 35px 35px;
  position: relative;
  width: 371px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  .login_title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
  }
  .input_box {
    width: auto;
    height: 55px;
    background: rgba(186, 202, 209, 0.2);
    border-radius: 28px;
    padding: 0 20px;
  }
  .input_icon {
    width: 18px;
    height: 18px;
    margin-right: 7px;
  }
  ::placeholder {
    color: #ccc;
  }
  .send {
    padding-left: 10px;
    font-weight: bold;
    color: 8F5AFC;
  }
  .forget {
    font-size: 16px;
    color: #333;
    display: block;
    text-align: right;
    margin-bottom: 66px;
  }
  .login_btn {
    height: 55px;
    line-height: 55px;
    background: #8f5afc;
    border-radius: 28px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    display: block;
    margin: 0 auto 16px;
  }
  .register_btn {
    // height: 60px;
    // line-height: 60px;
    color: #fd973d;
    // border-radius: 10px;
    // text-align: center;
    // color: #fff;
    // font-size: 18px;
    // display: block;
  }
  .noaccount {
    color: #aaa;
    display: block;
    text-align: center;
    margin: 20px 0;
  }
}
//
</style>
  
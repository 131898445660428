<template>
  <div class="bg-main">
    <Header></Header>
    <div class="content">
      <div class="pagemain">
        <div class="lh20 fs14 mb20">
          <span class="col9 pointer plans" @click="goindex">当前位置: 首页 》</span>
          <span class="col3">详情 》</span>
        </div>

        <div class="part2 flex" v-if="detail">
          <div class="flex-grow-1 mr30">

            <div  class="detail_part" v-html="detail.content">
            </div>

          </div>
          <div>

          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import {apiGetDetail} from "../request/api";

export default {
  data() {
    return {

      detail:null,
      show: false,
    };
  },
  created() {
    // 在组件创建之后，把默认选中项的value值赋给绑定的属性
    //如果没有这句代码，select中初始化会是空白的，默认选中就无法实现
    let _that = this;
    _that.id = _that.$route.query.id;
    _that.get_detail(_that.id);
    // 浏览器控制按钮前进后退触发函数
    window.addEventListener("popstate", this.popstate, false);
  },
  destroyed() {
    // 销毁vm组件
    // 避免堆栈溢出，多次创建、多次触发
    window.removeEventListener("popstate", this.popstate, false);
  },
  watch: {
    $route(to, from) {
      let _that = this;
      _that.id = _that.$route.query.id;
      _that.get_detail(_that.id);
      // 浏览器控制按钮前进后退触发函数
      window.addEventListener("popstate", this.popstate, false);
    }
  },
  mounted() {},

  methods: {
    get_detail(id){
      var that = this;
      apiGetDetail({
            id: id,
          })
          .then((res) => {
            that.detail = res.data;
          });
    },
    goindex(){
      this.$router.push("/");
    },
    change() {
      this.show = !this.show;
    },
    testTabClick(index) {
      console.log(index);
      this.tabIndex = index;
    },
    testTabClick2(index) {
      console.log(index);
      this.tabIndex2 = index;
    },
    testTabClick3(index) {
      console.log(index);
      this.tabIndex3 = index;
    },
    add: function (count) {
      this.count++;
    },
    subtract: function (count) {
      if (this.count <= 0) {
        alert("受不了啦，宝贝不能再减少啦");
        this.count = 0;
      } else {
        this.count -= 1;
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "../assets/css/style.css";
.content {
  padding-top: 17px;
  max-width: 100vw;
  margin-bottom: 0;
  background: #f8f8f8;
}

.pagemain {
  width: 1215px;
  margin: 0 auto;
  .part1 {
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    padding: 25px 32px 40px;
    margin-bottom: 30px;
    .imgs1 {
      width: 380px;
      height: 481px;
      display: block;
      object-fit: cover;
      margin-right: 44px;
    }
    .active,
    .unactive,.tab1:hover {
      width: 131px;
      text-align: center;
      height: 48px;
      line-height: 48px;
      background: rgba(143, 90, 252, 0.1);
      border-radius: 5px;
      color: #8f5afc;
    }
    .unactive {
      background: #eeeeee;
      color: #666;
    }
    .num_part {
      width: 131px;
      height: 34px;
      line-height: 34px;
      border-radius: 5px;
      border: 1px solid rgba(151, 151, 151, 0.1);
      .add_icon {
        width: 34px;
        height: 34px;
        display: block;
        object-fit: cover;
      }
    }
    .buy_btn {
      width: 261px;
      text-align: center;
      height: 70px;
      line-height: 70px;
      background: #8f5afc;
      border-radius: 10px;
    }
    .pay_part {
      width: 217px;
      height: 70px;
      line-height: 68px;
      background: #f5f5f5;
      border-radius: 14px;
      border: 1px solid #e5e5e5;
      select {
        background: #f5f5f5;
        width: auto !important;
      }
      .icon {
        width: 44px;
        height: 30px;
        display: block;
        object-fit: cover;
      }
      .arrow {
        width: 12px;
        height: 8px;
        display: block;
        object-fit: cover;
      }
      .country_part {
        background: #fff;
        position: absolute;
        width: 100%;
        z-index: 2;
        top: 70px;
        left: 0;
        max-height: 280px;
        overflow-y: scroll;
        .country {
          line-height: 70px;
        }
        .country:hover {
          background: #8f5afc;
          color: #fff;
        }
      }
    }
  }
  .part2 {
    padding-left: 8px;
    padding-bottom: 144px;
    .detail_part {
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
      border-radius: 6px;
      padding: 32px 24px 89px 31px;
      .detail_img {
        width: 894px;
        height: 470px;
        display: block;
        object-fit: cover;
      }
    }
  }
  .mb40 {
    margin-bottom: 40px;
  }

  .product {
    width: 224px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;

    .img {
      widows: 224px;
      height: 246px;
      display: block;
      object-fit: cover;
    }
    .main {
      padding: 11px 18px 16px 20px;
      .pr10 {
        padding-right: 8px;
      }
    }
    .cart_icon {
      width: 39px;
      height: 39px;
      display: block;
      object-fit: cover;
      margin-top: 10px;
    }
  }
  .product:hover {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.61);
  }
  .review_part {
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    padding: 30px 29px 28px 24px;
    .avatar {
      width: 44px;
      height: 44px;
      display: block;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
      margin-top: 13px;
      margin-right: 10px;
    }
    .name {
      width: 114px;
      margin-top: 24px;
    }
    .star {
      width: 17px;
      height: 17px;
      display: block;
      object-fit: cover;
      margin-right: 12px;
      margin-bottom: 16px;
    }
  }
}
</style>

<template>
  <div class="s-canvas pointer">
    <canvas
      id="s-canvas"
      :disable-scroll="true"
      :width="contentWidth"
      :height="contentHeight"
    ></canvas>
  </div>
</template>
  <script>
export default {
  name: "SIdentify",
  props: {
    identifyCode: {
      type: String,
      default: "1234",
    },
    fontSizeMin: {
      type: Number,
      default: 16,
    },
    fontSizeMax: {
      type: Number,
      default: 40,
    },
    backgroundColorMin: {
      type: Number,
      default: 180,
    },
    backgroundColorMax: {
      type: Number,
      default: 240,
    },
    colorMin: {
      type: Number,
      default: 50,
    },
    colorMax: {
      type: Number,
      default: 160,
    },
    lineColorMin: {
      type: Number,
      default: 40,
    },
    lineColorMax: {
      type: Number,
      default: 180,
    },
    dotColorMin: {
      type: Number,
      default: 0,
    },
    dotColorMax: {
      type: Number,
      default: 255,
    },
    contentWidth: {
      type: Number,
      default: 69,
    },
    contentHeight: {
      type: Number,
      default: 30,
    },
  },
  methods: {
    // 生成一个随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 生成一个随机的颜色
    randomColor(min, max) {
      let r = this.randomNum(min, max);
      let g = this.randomNum(min, max);
      let b = this.randomNum(min, max);
      return "rgb(" + r + "," + g + "," + b + ")";
    },
    drawPic() {
      let canvas = document.getElementById("s-canvas");
      let ctx = canvas.getContext("2d");
      ctx.textBaseline = "bottom";
      ctx.scale(1, 1);
      // 绘制背景
      //   ctx.fillStyle = this.randomColor(this.backgroundColorMin, this.backgroundColorMax) //随机背景
      ctx.fillStyle = "#fff"; //白背景
      ctx.fillRect(0, 0, this.contentWidth, this.contentHeight);
      // 绘制文字
      for (let i = 0; i < this.identifyCode.length; i++) {
        this.drawText(ctx, this.identifyCode[i], i);
      }
      //   this.drawLine(ctx)
      //   this.drawDot(ctx)
    },
    drawText(ctx, txt, i) {
      //   ctx.fillStyle = this.randomColor(this.colorMin, this.colorMax) //字体随机色
      ctx.fillStyle = "#333";
      //   ctx.font = this.randomNum(this.fontSizeMin, this.fontSizeMax) + 'px SimHei' //字体随机大小
      ctx.font = "16px SimHei";
      let x = (i + 1) * (this.contentWidth / (this.identifyCode.length + 1));
      let y = 25;
      var deg = this.randomNum(0, 0);
      // 修改坐标原点和旋转角度
      ctx.translate(x, y);
      ctx.rotate((deg * Math.PI) / 180);
      ctx.fillText(txt, 0, 0);
      // 恢复坐标原点和旋转角度
      ctx.rotate((-deg * Math.PI) / 180);
      ctx.translate(-x, -y);
    },
    drawLine(ctx) {
      // 绘制干扰线
      for (let i = 0; i < 8; i++) {
        ctx.strokeStyle = this.randomColor(
          this.lineColorMin,
          this.lineColorMax
        );
        ctx.beginPath();
        ctx.moveTo(
          this.randomNum(0, this.contentWidth),
          this.randomNum(0, this.contentHeight)
        );
        ctx.lineTo(
          this.randomNum(0, this.contentWidth),
          this.randomNum(0, this.contentHeight)
        );
        ctx.stroke();
      }
    },
    drawDot(ctx) {
      // 绘制干扰点
      for (let i = 0; i < 100; i++) {
        ctx.fillStyle = this.randomColor(0, 255);
        ctx.beginPath();
        ctx.arc(
          this.randomNum(0, this.contentWidth),
          this.randomNum(0, this.contentHeight),
          1,
          0,
          2 * Math.PI
        );
        ctx.fill();
      }
    },
  },
  watch: {
    identifyCode() {
      this.drawPic();
    },
  },
  mounted() {
    this.drawPic();
  },
};
</script>
  <style lang="less" scoped>
.s-canvas {
  widows: 69px;
  height: 30px;
  canvas {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    image-rendering: pixelated; /* 防止缩放时模糊 */
    image-rendering: -moz-crisp-edges; /* 火狐 */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit */
    image-rendering: -webkit-crisp-edges; /* Webkit */
    image-rendering: crisp-edges; /* 最新标准 */
    -ms-interpolation-mode: nearest-neighbor; /* IE */
  }
}
</style>
<template>
  <div class="bg-main">
    <Header></Header>
    <div class="content">
      <div class="pagemain flex">
        <div class="left_part mr20">
          <div class="info_part" v-if="userInfo">
            <img
                class="avatar pointer"
                :src="userInfo.avatar"
                alt=""
            />
            <div class="flex-box flex-center">
              <div class="name fs20 col26 lh28 mr5 pointer plans">{{userInfo.nickname}}</div>
              <img
                  class="gender_icon"
                  src="../assets/images/gender_icon1.png"
                  alt=""
              />
            </div>
          </div>
          <div class="order_part mb30">
            <div
                class="tablist flex-box fs14 fwb pointer"
                v-for="(item, index) in tablist"
                :key="index"
                @mouseover="showTabClick(index)"
                @click="testTabClick(index)"
                :class="tabIndex == index ? 'active colf' : 'unactive col26'"
            >
              <img
                  :key="index"
                  v-if="tabIndex == index || showIndex == index"
                  class="icon"
                  :src="item.activeIcon"
                  alt=""
              />
              <img v-else class="icon" :src="item.Icon" alt="" />
              <div class="flex-grow-1">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div  class="flex-grow-1 right_part mb30">



              <div class="flex-box mb30">
                <div class="mr55 fs20 fwb lh28 pointer plans col3">
                  我的评价
                </div>
              </div>
              <div
                  v-for="(item, index) in nowList.data"
                  :key="index"
                  class="product_part1"
              >
                <div class="product_top flex-box pl20 pr25 fs14 col3">
                  <div class="tab66">商品</div>
                  <div class="tab77 tc">评价时间</div>
                  <div class="tab88 tc">操作</div>
                </div>
                <div class="flex-box pt13 pb18 pl20 pr25 fs14 col3 lh20">
                  <div class="tab66 flex-box">
                    <img
                        class="imgs1"
                        :src="item.goods.image_text"
                        alt=""
                    />
                    <div class="flex-grow-1">{{item.goods.goods_name}}</div>
                  </div>
                  <div class="tab77 tc">{{item.createtime_text}}</div>
                  <div class="tab88 tc col-purple">
                    <div class="lh20 pointer plans" @click="godetail(item.id)">查看评价</div>
                  </div>
                </div>
              </div>
          <div class="m-nothing " v-if="nowList.total==0" >
            <img src="../assets/images/empty.png" alt="" />
            <div class="hit_txt">暂无数据~</div>
          </div>
          <div v-if="nowList.total>0">
            <div class="nomore" v-if="isShowNavBarLoading">没有更多了~</div>
            <!-- sumCount是数据总数50条 perCount是每页有几条 pageTurn是页数切换的方法 每页显示多少条在组件pagination.vue里修改 -->
            <Pagination v-else :sumCount="nowList.total" :perCount="nowList.per_page" @pageTurn="pageTurn"></Pagination>
          </div>

        </div>

      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
import {apiGetMyComment, apiGetNews, apiGetUserInfo} from "../request/api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      orderstate: 2, //0:我的订单 1：订单详情 2：发表评价
      status: 2, //0:订单详情待支付 1：订单详情待发货 2：订单详情已完成
      review: 1, //0:我的评价 1：评价详情
      tablist: [
        {
          activeIcon: require("../assets/images/order_icon1_on.png"),
          Icon: require("../assets/images/order_icon1.png"),
          name: "我的订单",
          url:'/myOrder'
        },
        {
          activeIcon: require("../assets/images/order_icon2_on.png"),
          Icon: require("../assets/images/order_icon2.png"),
          name: "消息中心",
          url: '/news'
        },
        {
          activeIcon: require("../assets/images/order_icon3_on.png"),
          Icon: require("../assets/images/order_icon3.png"),
          name: "帮助中心",
          url: '/helps'
        },
        {
          activeIcon: require("../assets/images/order_icon4_on.png"),
          Icon: require("../assets/images/order_icon4.png"),
          name: "个人信息",
          url: '/mine'
        },
        {
          activeIcon: require("../assets/images/order_icon5_on.png"),
          Icon: require("../assets/images/order_icon5.png"),
          name: "联系客服",
          url: '/kefu'
        },
        {
          activeIcon: require("../assets/images/order_icon6_on.png"),
          Icon: require("../assets/images/order_icon6.png"),
          name: "我的评价",
          url: '/comments'
        },
      ],
      tabIndex: 5,
      showIndex: null,
      tablist2: ["全部订单", "待支付", "待发货", "已完成"],
      tabIndex2: 0,
      genderlist: ["男", "女"],
      genderIndex: 0,
      show: false,

      //评分
      qStars: [false, false, false, false, false],
      eStars: [false, false, false, false, false],
      yellow: require("../assets/images/star.png"),
      gray: require("../assets/images/star_icon.png"),
      formData: {
        taskid: "",
        qscore: 0,
        escore: 0,
      },
      userInfo:null,
      isShowNavBarLoading:false,
      nowList:{  total: 0,
        current_page: 0,
        last_page: 1,
        data: [],
        per_page:15
      },
      pageSize:15
    };
  },

  mounted() {
    // 页面滚动到底部
    this.$nextTick(() => {
      // 一定要用nextTick

      //页面滚动条距离顶部高度等于这个盒子的高度
    //  this.$refs.scrollBox.scrollTop = this.$refs.scrollBox.scrollHeight;
    });
  },
  created() {
  this.init_Data();
  this.getUserInfo()
  },
  watch: {
    $route(to, from) {

      this.init_Data();
      this.getUserInfo()
    }
  },
  methods: {
    getUserInfo(){
      var json={},that=this;
      apiGetUserInfo(json).then(res => {
        // 获取数据成功后的其他操作
        if(!res.data.is_login){
          that.$message.error("请登录");
          that.$router.push({ path: "/login" });
        }
        that.userInfo=res.data.userinfo;

      }).catch(err=>{

      })
    },
    init_Data()
    {
      this.nowList.current_page=0;
      this.getList()
    },
    pageTurn(page) {
      this.nowList.current_page = page ;
      this.getList();
    },
    getList(){
      var json={},that=this;

      json.page = Number(that.nowList.current_page) + 1;

      if (json.page > 1 && that.nowList.current_page >= that.nowList.last_page) {
        return false;
      }
      apiGetMyComment(json).then(res => {
        // 获取数据成功后的其他操作
        const nowList = res.data;

        that.nowList=nowList;

      }).catch(err=>{

      })
    },
    godetail(id){
      this.$router.push({ path: "/commentDetail?id="+id });
    },
    onChange(e) {
      this.timeData = e
    },
    testTabClick(index) {

      this.tabIndex = index;
      if(this.tablist[this.tabIndex]){
        this.$router.push({ path: this.tablist[this.tabIndex].url });
      }
    },
    showTabClick(index) {
      this.showIndex = index;
    },
    testTabClick2(index) {

      this.tabIndex2 = index;
    },
    genderClick(index) {
      this.genderIndex = index;
    },
    showclick() {
      this.show = !this.show;
    },

  },
};
</script>
<style lang="less" scoped>
@import "../assets/css/style.css";
.content {
  padding-top: 17px;
  max-width: 100vw;
  margin-bottom: 0;
  background: #f8f8f8;
}

.pagemain {
  width: 1208px;
  margin: 0 auto;
}
.left_part {
  width: 280px;
  .info_part {
    padding: 57px 0;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    margin-bottom: 12px;
    .avatar {
      width: 114px;
      height: 114px;
      display: block;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto 14px;
    }
    .name {
      max-width: 170px;
    }
    .gender_icon {
      width: 10px;
      height: 16px;
      display: block;
    }
  }
  .order_part {
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    padding: 10px;
    .tablist {
      height: 60px;
      line-height: 60px;
      padding-left: 20px;
      .icon {
        width: 16px;
        height: 16px;
        display: block;
        object-fit: cover;
        margin-right: 9px;
      }
    }
    .active,
    .tablist:hover {
      background: #8f5afc;
      border-radius: 10px;
      color: #fff;
    }
    .unactive {
      background: transparent;
    }
  }
}
.right_part {
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  padding: 30px 40px;
  .product_part1 {
    border-radius: 4px;
    border: 1px solid #e2e2e2;
    margin-bottom: 20px;
    word-wrap: break-word;
    word-break: normal;
    .product_top {
      height: 44px;
      line-height: 44px;
      background: #f3f3f3;
      border-radius: 4px;
    }
    .tab1 {
      width: 225px;
      .imgs1 {
        width: 85px;
        height: 93px;
        display: block;
        object-fit: cover;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 15px;
      }
    }
    .tab2 {
      width: 66px;
      margin-right: 35px;
    }
    .tab3 {
      width: 140px;
      margin-right: 25px;
    }
    .tab4 {
      width: 82px;
      margin-right: 50px;
      .zt_btn1,
      .zt_btn2,
      .zt_btn3 {
        width: 69px;
        text-align: center;
        height: 26px;
        line-height: 26px;
        background: #db1919;
        border-radius: 5px;
        font-size: 12px;
        color: #ffffff;
        margin: 0 auto;
      }
      .zt_btn2 {
        background: #8f5afc;
      }
      .zt_btn3 {
        background: #cccccc;
      }
    }
    .tab5 {
      width: 57px;
      margin-right: 50px;
    }
  }
  //   个人信息
  .personal_info {
    width: 301px;
    min-height: 900px;
    margin: 0 auto;
    .avatar2 {
      width: 128px;
      height: 128px;
      display: block;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
      margin: 27px auto 16px;
    }
    .revise_btn {
      width: 144px;
      text-align: center;
      height: 48px;
      line-height: 48px;
      background: #8f5afc;
      border-radius: 29px;
      margin: 0 auto 30px;
    }
    input {
      width: 301px;
      height: 55px;
      line-height: 55px;
      background: rgba(186, 202, 209, 0.2);
      border-radius: 28px;
      padding-left: 17px;
    }
    .gender_part:last-of-type {
      margin-right: 0;
    }
    .active_gender,
    .unactive_gender,
    .gender_part:hover {
      width: 135px;
      text-align: center;
      height: 55px;
      line-height: 55px;
      color: #8f5afc;
      border-radius: 28px;
      background: rgba(143, 90, 252, 0.2);
    }
    .unactive_gender {
      background: rgba(186, 202, 209, 0.2);
      color: #333333;
    }
    .save_btn {
      width: 301px;
      text-align: center;
      height: 55px;
      line-height: 55px;
      background: #8f5afc;
      border-radius: 28px;
      margin: 0 auto;
    }
  }
}
.minh940 {
  min-height: 940px;
}
.border_b {
  border-bottom: 1px solid #e9e9e9;
}
.message_icon2 {
  width: 36px;
  height: 36px;
  display: block;
  object-fit: cover;
  margin-right: 6px;
}

// 客服聊天
.wrap {
  width: 598px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
}
.titleBox {
  height: 87px;
  width: 100%;
  line-height: 87px;
  background: #fbfbfb;
}
.kf_avatar {
  width: 48px;
  height: 48px;
  display: block;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}
.infoBottom {
  height: 168px;
  padding: 10px 37px 20px 15px;
  background: #fff;
}
/* 输入框 */
.infoInput {
  height: 126px;
  width: 100%;
  border: none;
  resize: none;
  box-sizing: border-box;
  background-color: #fff;
  color: #333;
}
.fasong {
  width: 81px;
  text-align: center;
  height: 43px;
  line-height: 41px;
  border-radius: 6px;
  border: 1px solid #c3c3c3;
  cursor: pointer;
  color: #333333;
  margin-top: 97px;
}
.infoIcon {
  height: 24px;
  width: 24px;
  display: block;
  object-fit: cover;
}

/* 头像 */
.head_portrait {
  width: 44px;
  height: 44px;
  display: block;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}

.main {
  .imgs1 {
    width: 85px;
    height: 85px;
    display: block;
    object-fit: cover;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 22px;
  }
  .pay_part {
    width: 238px;
    height: 75px;
    padding: 16px 0;
    border-radius: 10px;
    border: 1px solid #979797;
    .icon {
      width: 137px;
      height: 41px;
      display: block;
      margin: 0 auto;
    }
  }
  .btn_buy,
  .qx_buy {
    width: 158px;
    height: 55px;
    line-height: 55px;
    background: #a074fb;
    border-radius: 28px;
    margin-left: 40px;
  }
  .qx_buy {
    background: #fff;
    line-height: 53px;
    border: 1px solid #e4e4e4;
  }
}
.foot_code {
  width: 200px;
  height: 200px;
  display: block;
  object-fit: cover;
  margin: 15% auto 30px;
}
.pl55 {
  padding-left: 55px;
}
</style>

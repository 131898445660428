<template>
  <div class="bg-main">
    <Header></Header>
    <div class="content">
      <div class="pagemain ">
            <div class="minh940 " >
              <div class="fs20 col3 fwb lh28 mb20">帮助中心</div>
              <div v-for="(item, index) in nowList.data" :key="index" class="border_b">
                <div @click="showclick(item.id)" class="fs16 ptb20 col3 fwb pointer">
                  {{item.title}}
                </div>
                <div class="pb20 fs14 col3 lh24" v-if="rSelect.indexOf(item.id)!=-1">
                 {{item.content}}
                </div>
              </div>
              <div class="m-nothing " v-if="nowList.total==0" >
          <img src="../assets/images/empty.png" alt="" />
          <div class="hit_txt">暂无数据~</div>
        </div>
            </div>
     
        <div v-if="nowList.total>0">
            <div class="nomore" v-if="isShowNavBarLoading">没有更多了~</div>
            <!-- sumCount是数据总数50条 perCount是每页有几条 pageTurn是页数切换的方法 每页显示多少条在组件pagination.vue里修改 -->
            <Pagination v-else :sumCount="nowList.total" :perCount="nowList.per_page" @pageTurn="pageTurn"></Pagination>
        </div>


      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Pagination from "@/components/pagination.vue";
import {apiGetHelps, apiGetNews} from "../request/api";
export default {
  name: "helpCenter",
  components: {
    Pagination,
  },
  data() {

    return {
      rSelect:[],
      isShowNavBarLoading:false,
      nowList:{  total: 0,
        current_page: 0,
        last_page: 1,
        data: [],
        per_page:15
      },
      pageSize:15
    };
  },
  created() {
    this.init_Data()
  },
  watch: {
    $route(to, from) {
      this.init_Data()
    }
  },
  methods: {
    init_Data()
    {
       this.nowList.current_page=0;
       this.getList()
    },
    pageTurn(page) {
      this.nowList.current_page = page ;
      this.getList();
    },
    getList(){
      var json={},that=this;

      json.page = Number(that.nowList.current_page) + 1;

      if (json.page > 1 && that.nowList.current_page >= that.nowList.last_page) {
        return false;
      }
      apiGetNews(json).then(res => {
        // 获取数据成功后的其他操作
        const nowList = res.data;

      that.nowList=nowList;

      }).catch(err=>{

      })
    },
    showclick(id){
      if (this.rSelect.indexOf(id) == -1) {
        //选中添加到数组里
        this.$nextTick(function() {

          this.rSelect.push(id);

        })
      } else {
        this.rSelect.splice(this.rSelect.indexOf(id), 1); //取消
        // this.selectedGreenery.pop(index)
      }
    },

  },
}

</script>

<style lang="less" scoped>
@import "../assets/css/style.css";
.content {
  padding-top: 17px;
  max-width: 100vw;
  margin-bottom: 0;
  background: #f8f8f8;
}

.pagemain {
  width: 1208px;
  margin: 0 auto 30px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  padding: 30px 40px;
}
.left_part {
  width: 280px;
  .info_part  {
    padding: 57px 0;
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    margin-bottom: 12px;
    .avatar {
      width: 114px;
      height: 114px;
      display: block;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto 14px;
    }
    .name {
      max-width: 170px;
    }
    .gender_icon {
      width: 10px;
      height: 16px;
      display: block;
    }
  }
  .order_part {
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    padding: 10px;
    .tablist {
      height: 60px;
      line-height: 60px;
      padding-left: 20px;
      .icon {
        width: 16px;
        height: 16px;
        display: block;
        object-fit: cover;
        margin-right: 9px;
      }
    }
    .active,
    .tablist:hover {
      background: #8f5afc;
      border-radius: 10px;
      color: #fff;
    }
    .unactive {
      background: transparent;
    }
  }
}
.right_part {
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  padding: 30px 40px;
  .product_part1 {
    border-radius: 4px;
    border: 1px solid #e2e2e2;
    margin-bottom: 20px;
    word-wrap: break-word;
    word-break: normal;
    .product_top {
      height: 44px;
      line-height: 44px;
      background: #f3f3f3;
      border-radius: 4px;
    }
    .tab1 {
      width: 225px;
      .imgs1 {
        width: 85px;
        height: 93px;
        display: block;
        object-fit: cover;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 15px;
      }
    }
    .tab2 {
      width: 66px;
      margin-right: 35px;
    }
    .tab3 {
      width: 140px;
      margin-right: 25px;
    }
    .tab4 {
      width: 82px;
      margin-right: 50px;
      .zt_btn1,
      .zt_btn2,
      .zt_btn3 {
        width: 69px;
        text-align: center;
        height: 26px;
        line-height: 26px;
        background: #db1919;
        border-radius: 5px;
        font-size: 12px;
        color: #ffffff;
        margin: 0 auto;
      }
      .zt_btn2 {
        background: #8f5afc;
      }
      .zt_btn3 {
        background: #cccccc;
      }
    }
    .tab5 {
      width: 57px;
      margin-right: 50px;
    }
  }
  //   个人信息
  .personal_info {
    width: 301px;
    min-height: 900px;
    margin: 0 auto;
    .avatar2 {
      width: 128px;
      height: 128px;
      display: block;
      object-fit: cover;
      border-radius: 50%;
      overflow: hidden;
      margin: 27px auto 16px;
    }
    .revise_btn {
      width: 144px;
      text-align: center;
      height: 48px;
      line-height: 48px;
      background: #8f5afc;
      border-radius: 29px;
      margin: 0 auto 30px;
    }
    input {
      width: 301px;
      height: 55px;
      line-height: 55px;
      background: rgba(186, 202, 209, 0.2);
      border-radius: 28px;
      padding-left: 17px;
    }
    .gender_part:last-of-type {
      margin-right: 0;
    }
    .active_gender,
    .unactive_gender,
    .gender_part:hover {
      width: 135px;
      text-align: center;
      height: 55px;
      line-height: 55px;
      color: #8f5afc;
      border-radius: 28px;
      background: rgba(143, 90, 252, 0.2);
    }
    .unactive_gender {
      background: rgba(186, 202, 209, 0.2);
      color: #333333;
    }
    .save_btn {
      width: 301px;
      text-align: center;
      height: 55px;
      line-height: 55px;
      background: #8f5afc;
      border-radius: 28px;
      margin: 0 auto;
    }
  }
}
.minh940 {
  min-height: 940px;
}
.border_b {
  border-bottom: 1px solid #e9e9e9;
}
.message_icon2 {
  width: 36px;
  height: 36px;
  display: block;
  object-fit: cover;
  margin-right: 6px;
}

// 客服聊天
.wrap {
  width: 598px;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
}
.titleBox {
  height: 87px;
  width: 100%;
  line-height: 87px;
  background: #fbfbfb;
}
.kf_avatar {
  width: 48px;
  height: 48px;
  display: block;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}
.infoBottom {
  height: 168px;
  padding: 10px 37px 20px 15px;
  background: #fff;
}
/* 输入框 */
.infoInput {
  height: 126px;
  width: 100%;
  border: none;
  resize: none;
  box-sizing: border-box;
  background-color: #fff;
  color: #333;
}
.fasong {
  width: 81px;
  text-align: center;
  height: 43px;
  line-height: 41px;
  border-radius: 6px;
  border: 1px solid #c3c3c3;
  cursor: pointer;
  color: #333333;
  margin-top: 97px;
}
.infoIcon {
  height: 24px;
  width: 24px;
  display: block;
  object-fit: cover;
}

/* 头像 */
.head_portrait {
  width: 44px;
  height: 44px;
  display: block;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}

/* 信息列表 */
.infoTop {
  height: 587px;
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
  padding: 14px 20px 20px 20px;
  box-sizing: border-box;
  overflow: auto;
  .data {
    display: inline-block;
    text-align: center;
    padding: 0 7px;
    height: 18px;
    line-height: 18px;
    background: rgba(187, 187, 187, 0.1);
    border-radius: 9px;
    color: #a3abba;
    line-height: 17px;
    font-size: 12px;
  }
}
/* 对方发的信息样式 */
.chatInfoLeft {
  min-height: 70px;
  margin-left: 10px;
  margin-top: 10px;
  display: flex;
}
.chatLeft {
  margin-left: 15px;
  flex: 1;
}
.chatLeft .text {
  color: #000000;
  margin-top: 5px;
  background-color: #e4e4e4;
  display: inline-block;
  padding: 7px 15px;
  border-radius: 1px 8px 8px 8px;
  max-width: 50%;
  /* 忽略多余的空白，只保留一个空白 */
  white-space: normal;
  /* 换行显示全部字符 */
  word-break: break-all;
  white-space: pre-wrap;
}

/* 自己发的信息样式 */
.chatInfoRight {
  min-height: 70px;
  margin-left: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
}
.chatRight {
  margin-right: 15px;
  flex: 1;
  /* 用align-items把元素靠右对齐 */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.chatRight .text {
  color: #fff;
  margin-top: 5px;
  background-color: #8f5afc;
  display: inline-block;
  padding: 7px 15px;
  border-radius: 8px 1px 8px 8px;
  max-width: 50%;
  /* 忽略多余的空白，只保留一个空白 */
  white-space: normal;
  /* 换行显示全部字符 */
  word-break: break-all;
  white-space: pre-wrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

/* 简略信息 */
.userInfo {
  width: 100px;
  font-size: 14px;
  color: #ccc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
}

/* 滚动条样式 */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #dbd9d9;
  border-radius: 3px;
}
.main {
  .imgs1 {
    width: 85px;
    height: 85px;
    display: block;
    object-fit: cover;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 22px;
  }
  .pay_part {
    width: 238px;
    height: 75px;
    padding: 16px 0;
    border-radius: 10px;
    border: 1px solid #979797;
    .icon {
      width: 137px;
      height: 41px;
      display: block;
      margin: 0 auto;
    }
  }
  .btn_buy,
  .qx_buy {
    width: 158px;
    height: 55px;
    line-height: 55px;
    background: #a074fb;
    border-radius: 28px;
    margin-left: 40px;
  }
  .qx_buy {
    background: #fff;
    line-height: 53px;
    border: 1px solid #e4e4e4;
  }
}
</style>